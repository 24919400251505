import React from 'react';
import {Link} from "react-router-dom";
import brand from './brand.png';

const {$} = window;

class AppHeader extends React.Component {

    componentDidMount() {
        $.HSCore.components.HSHeader.init($('#js-header'));
        $.HSCore.helpers.HSHamburgers.init('.hamburger');
    }

    render() {
        return (
            <header id="js-header" className="u-header u-header--sticky-top u-header--change-appearance"
                    data-header-fix-moment="100">
                <div
                    className="u-header__section u-header__section--dark g-bg-black-opacity-0_3 g-transition-0_3 g-py-20"
                    data-header-fix-moment-exclude="g-bg-black-opacity-0_3 g-py-20"
                    data-header-fix-moment-classes="g-bg-black-opacity-0_8 g-py-10">
                    <nav className="navbar navbar-expand-lg py-0">
                        <div className="container g-pos-rel">
                            <Link to="/" className="js-go-to navbar-brand u-header__logo g-width-130 g-mr-60"
                                  data-type="static">
                                <img className="img-fluid u-header__logo-img u-header__logo-img--main"
                                     src={brand} alt="Description"/>
                            </Link>

                            <div id="navBar" className="collapse navbar-collapse w-100" data-mobile-scroll-hide="true">
                                <div className="navbar-collapse align-items-center flex-sm-row">
                                    <ul id="js-scroll-nav"
                                        className="navbar-nav text-uppercase g-font-weight-700 g-font-size-13 g-py-20 g-py-0--lg">
                                        {this.props.connected &&
                                        <li className="nav-item g-mr-15--lg g-mb-7 g-mb-0--lg active">
                                            <Link to="/data" className="nav-link p-0">Data List</Link>
                                        </li>}
                                    </ul>
                                </div>

                                {
                                    this.props.connected === null ? <Link
                                        className="btn btn-lg text-uppercase u-btn-primary g-font-weight-700 g-font-size-12 g-rounded-30 g-px-40 g-py-15"
                                        to="/login">Log in</Link> : <button
                                        className="btn btn-lg text-uppercase u-btn-primary g-font-weight-700 g-font-size-12 g-rounded-30 g-px-40 g-py-15"
                                        onClick={this.props.onLogout}>Log out</button>
                                }
                            </div>
                            <button
                                className="navbar-toggler btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0"
                                type="button"
                                aria-label="Toggle navigation"
                                aria-expanded="false"
                                aria-controls="navBar"
                                data-toggle="collapse"
                                data-target="#navBar">
                                <span className="hamburger hamburger--slider">
                                  <span className="hamburger-box">
                                    <span className="hamburger-inner"/>
                                  </span>
                                </span>
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default AppHeader;