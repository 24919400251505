import React from 'react';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import './App.css';
import AppHeader from './AppHeader';
import AppLogin from "./AppLogin";
import AppHome from "./AppHome";
import Alert, {sendErrorAlert} from "./Alert";
import AppDataList from "./AppDataList";
import Footer from './Footer';

const CONNECTED_KEY = 'connected';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            connected: null,
            users: []
        };
        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount() {
        fetch('/api/auth')
            .then(res => {
                if (res.status === 401) {
                    return null;
                }
                return res.json();
            })
            .then(result => {
                this.setState({[CONNECTED_KEY]: result});
                localStorage.setItem(CONNECTED_KEY, result);
            })
            .catch(error => {
                console.error('error', error);
                sendErrorAlert({
                    title: 'Login error',
                    message: error.message
                });
            });

        fetch('/api/user/list?page=-1&perPage=-1')
            .then(res => res.json())
            .then(result => {
                console.log('result', result);
                if (result.code && result.code / 100 !== 2) {
                    sendErrorAlert({
                        title: 'User List',
                        message: result.message
                    });
                } else {
                    this.setState({
                        users: result
                    });
                }
            });
    }

    onLogin(event) {
        if (event.result) {
            this.setState({[CONNECTED_KEY]: event.result});
            localStorage.setItem(CONNECTED_KEY, event.result);
            window.location.hash = '#/';
        } else {
            localStorage.removeItem(CONNECTED_KEY);
            this.setState({[CONNECTED_KEY]: null});
            sendErrorAlert({
                title: 'Login error',
                message: event.error.message
            });
        }
    }

    onLogout(event) {
        console.log('event', event);
        fetch('/api/auth', {
            method: 'DELETE'
        }).catch(error => {
            console.error('error', error);
            sendErrorAlert({
                title: 'Login error',
                message: error.message
            });
        });
        localStorage.removeItem(CONNECTED_KEY);
        this.setState({[CONNECTED_KEY]: null});
        window.location.hash = '/';
    }

    render() {
        return (
            <Router>
                <Alert/>
                <AppHeader connected={this.state.connected} onLogout={this.onLogout}/>
                <div className="App">
                    <Switch>
                        <Route exact path="/">
                            <AppHome/>
                        </Route>
                        {this.state.connected && <Route path="/data">
                            <AppDataList users={this.state.users}/>
                        </Route>}
                        <Route path="/login">
                            <div className="text-center text-md-left g-py-50">
                                <AppLogin onLogin={this.onLogin}/>
                            </div>
                        </Route>
                    </Switch>
                </div>
                <Footer/>
            </Router>
        );
    }
}

export default App;
