import React from 'react';
import {sendErrorAlert, sendInfoAlert, sendSuccessAlert} from "./Alert";
import {CKFileUploadAdapterPlugin} from "./CKFileUploadAdapter";

const {$, ClassicEditor} = window;

class InputForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            inputKey: '',
            inputValue: '',
            serverOnly: false,
            html: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClone = this.handleClone.bind(this);
        this.editor = null;
    }

    resetData() {
        this.setState({
            id: '',
            inputKey: '',
            inputValue: '',
            serverOnly: false,
            html: false
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user && this.state.id > 0) {
            this.resetData();
        }
        if (prevProps.data !== this.props.data) {
            this.setState(this.props.data || {});
            $("html, body").animate({scrollTop: 0}, "slow");
        }
        if (prevState.html !== this.state.html) {
            this.setHtmlEditor();
        } else if (this.state.html && prevState.inputKey !== this.state.inputKey && prevState.inputValue !== this.state.inputValue) {
            this.editor.setData(this.state.inputValue);
        }
    }

    setHtmlEditor() {
        if (this.editor) {
            this.editor.destroy();
            this.editor = null;
        }
        if (this.state.html) {
            let inputEditor = document.querySelector('#inputValue');
            if (inputEditor) {
                ClassicEditor
                    .create(inputEditor, {
                        extraPlugins: [CKFileUploadAdapterPlugin]
                    })
                    .then(editor => {
                        this.editor = editor;
                        this.editor.model.document.on('change:data', (e) => {
                            let inputValue = this.editor.getData();
                            this.setState({
                                inputValue: inputValue
                            });
                        });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        if (name === 'user') {
            value = {id: value};
        }
        this.setState({
            [name]: value
        });
    }

    handleClone(event) {
        event.preventDefault();
        console.log('id', this.state.id);
        this.setState({
            id: ''
        });
        sendInfoAlert({
            title: 'Input Form',
            message: this.state.inputKey + ' cloned',
            timeout: 3000
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let data = this.state;
        data.user = this.props.user;
        fetch('/api/data', {
            method: data.id > 0 ? 'PUT' : 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(result => {
                if (result.code && result.code / 100 !== 2) {
                    sendErrorAlert({
                        title: 'Input Form',
                        message: result.message
                    });
                } else {
                    if (typeof this.props.onChange === 'function') {
                        this.props.onChange(result);
                    }
                    sendSuccessAlert({
                        title: 'Input Form',
                        message: this.state.inputKey + ' added',
                        timeout: 3000
                    });
                    this.resetData();
                }
            })
            .catch(error => {
                sendErrorAlert({
                    title: 'Input Form',
                    message: error.message
                });
            });
    }

    render() {
        return (
            <div id="InputForm" className='InputForm'>
                <form className="g-brd-around g-brd-gray-light-v4 g-pa-30 g-mb-30" onSubmit={this.handleSubmit}>
                    <input name="id" type="hidden" value={this.state.id} onChange={this.handleChange}/>
                    <label className="form-check-inline u-check g-pl-25">
                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                               type="checkbox"
                               name="html"
                               checked={this.state.html} onChange={this.handleChange}/>
                        <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                            <i className="fa" data-check-icon=""/>
                        </div>
                        Edit as html
                    </label>
                    <div className="form-group g-mb-20">
                        <input id="inputKey" className="form-control form-control-md rounded-0"
                               type="text"
                               name="inputKey"
                               value={this.state.inputKey}
                               placeholder="Input Key *"
                               required={true} onChange={this.handleChange}/>
                    </div>
                    <div className="form-group g-mb-20">
                        {!this.state.html &&
                        <textarea id="inputValue" className="form-control form-control-md rounded-0"
                                  name="inputValue"
                                  rows={5}
                                  value={this.state.inputValue}
                                  placeholder="Input Value *"
                                  required={true} onChange={this.handleChange}/>}
                        {this.state.html &&
                        <div id="inputValue" dangerouslySetInnerHTML={{__html: this.state.inputValue}}/>}
                    </div>
                    <label className="form-check-inline u-check g-pl-25">
                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                               type="checkbox"
                               name="serverOnly"
                               checked={this.state.serverOnly} onChange={this.handleChange}/>
                        <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                            <i className="fa" data-check-icon=""/>
                        </div>
                        Server Only
                    </label>
                    <button type="submit" className="btn btn-primary g-mr-10 btn-md g-mb-15 pull-right">Save
                    </button>
                    <button type="button" disabled={this.state.id === ''} onClick={this.handleClone}
                            className="btn btn-md u-btn-darkpurple g-mr-10 g-mb-15 pull-right">Clone
                    </button>
                </form>
            </div>
        );
    }
}

export default InputForm;