import React from 'react';
import './AppDataList.css'
import InputForm from "./InputForm";
import {sendErrorAlert} from "./Alert";

const {$, confirm} = window;

class AppDataList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null,
            selectedId: '',
            data: null,
            selectedData: null,
            token: '',
            displayedList: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (name === 'selectedUser') {
            let user = (this.props.users || []).find(u => u.id === value * 1);
            if (user) {
                this.setState({selectedUser: user, selectedId: user.id + ''});
                this.getDataList(user.id);
            }
        } else {
            this.setState({[name]: value});
        }
        if (name === 'token') {
            this.updateList(value);
        }
    }

    handleEdit(item, event) {
        event.preventDefault();
        item.html = (item.inputValue || '').startsWith('<');
        this.setState({
            selectedData: item
        });
    }

    handleDelete(item, event) {
        let ok = confirm('Do you want to delete ' + item.inputKey + '[' + item.id + ']');
        if (ok) {
            fetch('/api/data/' + item.id, {
                method: 'DELETE'
            })
                .then(res => {
                    let data = (this.state.data || []).filter(d => d.id !== item.id);
                    this.setState({
                        data: data
                    });
                    this.updateList();
                })
                .catch(error => {
                    console.error('error', error);
                });
        }
    }

    getDataList(userId) {
        fetch('/api/data/list/' + userId)
            .then(res => res.json())
            .then(result => {
                this.setState({data: result});
                this.updateList();
            })
            .catch(error => {
                console.error('error', error);
                sendErrorAlert({
                    title: 'User List',
                    message: error.message
                });
            });
    }

    updateList(event) {
        let token = event;
        if (event === undefined) {
            token = this.state.token;
        }
        token = token.trim();
        let displayedList = (this.state.data || [])
            .filter(item => item.inputKey.toLowerCase().indexOf(token.toLowerCase()) >= 0);
        this.setState({
            displayedList
        });
    }

    componentDidMount() {
        if ((this.props.users || []).length > 0) {
            $.HSCore.components.HSSelect.init('.user-select', {
                change: this.handleChange
            });
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.users || []).length && prevProps.users !== this.props.users) {
            $.HSCore.components.HSSelect.init('.user-select', {
                change: this.handleChange
            });
        }
    }

    handleAdd(event) {
        if (event) {
            let data = (this.state.data || []).filter(d => d.id !== event.id);
            data = [event, ...data];
            this.setState({
                data: data
            });
            this.updateList();
        }
    }

    render() {
        return (
            <div className="container g-mt-100 AppDataList">
                <div className="">
                    <form className="g-brd-around g-brd-gray-light-v4 g-pa-10 g-mb-10">
                        <div className="form-group g-mb-20">
                            <select
                                className="user-select w-100 u-select-v1 g-brd-gray-light-v3 g-color-black g-color-primary--hover g-bg-white g-py-12"
                                onChange={this.handleChange}
                                name="selectedUser" value={this.state.selectedId}
                                data-placeholder="Select"
                                data-open-icon="fa fa-angle-down"
                                data-close-icon="fa fa-angle-up">
                                <option/>
                                {(this.props.users || []).map(user => <option
                                    className="g-brd-none g-color-black g-color-white--hover g-color-white--active g-bg-primary--hover g-bg-primary--active"
                                    key={user.id} value={user.id}>{user.name}</option>)}
                            </select>
                        </div>
                    </form>
                    <InputForm onChange={this.handleAdd} data={this.state.selectedData} user={this.state.selectedUser}/>
                </div>
                <section className="g-bg-secondary g-py-20">
                    <div className="container">
                        <div className="text-center g-mb-20">
                            <h2 className="h4">Data List</h2>
                        </div>
                        <div className="row justify-content-center g-mx-5--md">
                            <div className="col-sm-10 col-lg-11 g-px-0--sm g-mb-30">
                                <input className="form-control rounded-0 g-brd-gray-light-v3 g-px-20 g-py-15"
                                       type="text"
                                       name="token"
                                       onChange={this.handleChange}
                                       value={this.state.token}
                                       placeholder="Filter..."/>
                            </div>
                            <div className="col-sm-2 col-lg-1 g-px-0--sm g-mb-30">
                                <button
                                    className="btn btn-block u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 rounded-0 g-px-18 g-py-15"
                                    type="button">
                                    {this.state.displayedList.length}
                                </button>
                            </div>
                        </div>
                        <div className="data-list">
                            {(this.state.displayedList || []).map(item => {
                                return <article key={item.id} className="d-md-table w-100 g-bg-white g-mb-1">
                                    <div className="d-md-table-cell align-middle g-py-15 g-px-20">
                                        <h3 className="h6 g-font-weight-700 text-uppercase">
                                            <a className="g-color-gray-dark-v2" href="/#"
                                               onClick={(e) => e.preventDefault()}>{item.inputKey}</a>
                                        </h3>
                                        <em className="g-color-gray-dark-v5 g-font-style-normal">{(item.inputValue || '').substr(0, 100)}</em>
                                    </div>
                                    <div className="d-md-table-cell align-middle text-md-right g-pa-20">
                                        <label className="form-check-inline u-check g-pl-25">
                                            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                   type="checkbox"
                                                   name="serverOnly"
                                                   readOnly={true}
                                                   checked={item.serverOnly} onChange={this.handleChange}/>
                                            <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                                                <i className="fa" data-check-icon=""/>
                                            </div>
                                            Server Only
                                        </label>
                                        <div className="g-mt-minus-10 g-mx-minus-5">
                                            <button
                                                className="btn btn-lg u-btn-primary g-font-weight-600 g-font-size-12 text-uppercase g-mx-5 g-mt-10"
                                                onClick={this.handleEdit.bind(this, item)}>Edit
                                            </button>
                                            <button
                                                className="btn btn-lg u-btn-red g-font-weight-600 g-font-size-12 text-uppercase g-mx-5 g-mt-10"
                                                onClick={this.handleDelete.bind(this, item)}>Delete
                                            </button>
                                        </div>
                                    </div>
                                </article>
                            })}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default AppDataList;